import React from "react";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const typographyStyle = ({
  theme,
  variant,
  color,
  bold
}: {
  theme?: any,
  variant?: string;
  color?: string;
  bold?: boolean;
  className?: string;
}) => css`
  ${variant && variant !== "inherit" && theme.typography[variant]};
  ${bold && theme.typography.bold};
  color: ${color ? theme.colors[color] : "inherit"};
  &.italic, 
  .italic {
    font-style: italic;
  }
`;

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(
  ({ as: T = "div", ...props }: { as: string }, ref) => (
    // @ts-ignore
    <T {...props} ref={ref} />
  )
);

export default styled(Component, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "color",
})`
  ${typographyStyle}
`;