import React, { createContext, useContext, useMemo, useState } from "react";
import StyledPopup from "../styles/units/Popup.style";
import { Cross } from "./icons";
import ContactPopup from "../components/globals/ContactPopup";
import Icon from "../components/units/Icon";
import ProductPopup from "../components/units/ProductPopup";

const Popup = ({
  isPopupOpen,
  onClosePopup,
  children,
  ...p
}: {
  isPopupOpen: boolean;
  onClosePopup: () => void;
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <StyledPopup className={`${isPopupOpen ? "visible" : ""}`} {...p}>
      <div className="popup">
        <button
          className="close-btn"
          onClick={onClosePopup}
          aria-label="Close popup button"
        >
          <Icon {...Cross} />
        </button>
        {children}
      </div>
    </StyledPopup>
  );
};

export enum availablePopupTypes {
  CONTACT,
  PRODUCT,
}

export const PopupContext = createContext({
  setPopupContent: (a: availablePopupTypes) => {},
  handlePopupState: () => {},
  isPopupOpen: false,
  setProductPopupData: (a: number) => {},
});

export const PopupContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [popupContent, setPopupContent] = useState(availablePopupTypes.CONTACT);
  const [productPopupData, setProductPopupData] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handlePopupState = () => {
    setIsPopupOpen(!isPopupOpen);
    document.body.classList.toggle("no-scroll");
  };

  const value = useMemo(
    () => ({
      setPopupContent,
      handlePopupState,
      isPopupOpen,
      setProductPopupData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPopupOpen, popupContent, productPopupData]
  );

  return (
    <PopupContext.Provider value={value}>
      {children}
      <Popup isPopupOpen={isPopupOpen} onClosePopup={handlePopupState}>
        {popupContent === availablePopupTypes.CONTACT ? (
          <ContactPopup></ContactPopup>
        ) : (
          <ProductPopup index={productPopupData}></ProductPopup>
        )}
      </Popup>
    </PopupContext.Provider>
  );
};

export const usePopupContext = () => {
  return useContext(PopupContext);
};
