// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledHeaderWVideo from "../../styles/pageparts/HeaderWVideo.style";
import { pagepartImgs } from "../../utils/globalContent";
import { ChevronDown, Play } from "../../utils/icons";
import Button, { ButtonVariant } from "../units/Button";

type HeaderWVideoContentType = {
  title: string;
  tag: string;
  buttons_labels: Array<string>;
};

const HeaderWVideo = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("header_w_video") as HeaderWVideoContentType;

  const shortVideoRef = useRef(null);
  const videoRef = useRef(null);
  const toggleFullScreen = () => {
    if (!videoRef.current) return;

    shortVideoRef.current.pause();
    videoRef.current.classList.add("active");
    videoRef.current.play();

    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    }
  };

  useEffect(() => {
    const exitFullscreen = () => {
      if (!document.fullscreenElement) {
        videoRef.current.pause();
        videoRef.current.classList.remove("active");
        videoRef.current.currentTime = 0;
        shortVideoRef.current.play();
      }
    }

    document.addEventListener('fullscreenchange', exitFullscreen);
    document.addEventListener('webkitfullscreenchange', exitFullscreen);
    document.addEventListener('mozfullscreenchange', exitFullscreen);
    videoRef.current.addEventListener('webkitendfullscreen', exitFullscreen);

    // window.onload = () => {
    //   setTimeout(() => {
    //     shortVideoRef.current.play();
    //   }, 3000)
    // };
  }, []);

  return (
    <StyledHeaderWVideo className="container" {...p}>
      <div className="tag">{content["tag"]}</div>
      <h1 dangerouslySetInnerHTML={{ __html: content["title"] }}></h1>
      <Button icon={ChevronDown} href="#about-us-section">{content["buttons_labels"][0]}</Button>
      <Button
        icon={Play}
        tag="button"
        variant={ButtonVariant.FULL_SEMI_TRANSPARENT}
        onCustomClick={toggleFullScreen}
      >
        {content["buttons_labels"][1]}
      </Button>
      <video
        className="background"
        poster={pagepartImgs.header_w_video.preview}
        ref={shortVideoRef}
        // preload="none"
        autoPlay
        muted
        playsInline
        loop
      >
        <source src={pagepartImgs.header_w_video.shortLoop} type="video/mp4" />
      </video>
      <video
        className="full-video"
        ref={videoRef}
        preload="none"
      >
        <source src={pagepartImgs.header_w_video.fullVideo} type="video/mp4" />
      </video>
    </StyledHeaderWVideo>
  );
};

export default HeaderWVideo;
