import styled from "@emotion/styled";

export default styled.section`
  position: relative;
  padding-top: 580px;
  padding-bottom: 200px;
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  .text-content {
    max-width: 38vw;
    min-height: 35vh;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 300%;
    z-index: -1;
  }

  &::after {
    left: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.wetAsphalt};
    transform-origin: left top;
    transform: rotate(30deg);
  }

  &::before {
    right: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.landscapeGreen};
    transform-origin: right center;
    transform: rotate(-30deg);
  }

  ${({ theme }) => theme.mediaDown.desktopBig} {
    padding-top: 520px;
    padding-bottom: 120px;
  }

  ${({ theme }) => theme.mediaDown.desktopSmall} {
    padding-top: 315px;
    padding-bottom: 60px;

    .text-content {
      max-width: 60vw;
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    .text-content {
      width: 100%;
      max-width: 100%;
    }
  }
`;
