import React from "react";
import Footer from "./components/globals/Footer";
import NavHeader from "./components/globals/NavHeader";
import { GetText } from "./languages/LanguageProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";

type HeadContentType = {
  title: string;
  meta_description: string;
};

const Page = ({ children }: React.PropsWithChildren) => {
  const content = GetText("head") as HeadContentType;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.meta_description} />
      </Helmet>
      <NavHeader></NavHeader>
      {children}
      <Footer></Footer>
    </HelmetProvider>
  );
};

export default Page;
