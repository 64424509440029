import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledUspsGridSlider from "../../styles/pageparts/UspsGridSlider.style";
import { pagepartImgs } from "../../utils/globalContent";
import SwiperLayout, {
  SwiperScreenSize,
  SwiperType,
} from "../layouts/SwiperLayout";
import Typography from "../units/Typography";

type UspsContentType = {
  items: [
    {
      title: string;
      description: string;
    }
  ];
};

const UspsGridSlider = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("usps_grid_slider") as UspsContentType;

  return (
    <StyledUspsGridSlider className="dark-bg" {...p}>
      <SwiperLayout
        swiperScreenSize={SwiperScreenSize.MOBILE}
        swiperType={SwiperType.SLIDE_OVER}
        className="usps-slider"
      >
        {content["items"].map((item, ix) => (
          <div className="usp-item" key={ix}>
            <div className="img-wrapper">
              <img
                className="background"
                src={pagepartImgs.usps_grid_slider[ix]}
                alt={item["title"]}
                loading="lazy"
              />
            </div>
            <Typography
              tag="div"
              variant="heading5"
              className="heading5"
            >
              {item["title"]}
            </Typography>
            <p>{item["description"]}</p>
          </div>
        ))}
      </SwiperLayout>
    </StyledUspsGridSlider>
  );
};

export default UspsGridSlider;
