import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledFooter from "../../styles/globals/Footer.style";
import { albourghLogo, emailAddress, socialLinks } from "../../utils/globalContent";
import { Email } from "../../utils/icons";
import Icon from "../units/Icon";
import Typography from "../units/Typography";

type FooterContentType = {
  brand_description: string;
  contact_label: string;
  privacy_label: string;
  disclaimer_label: string;
  cookies_label: string;
};

const Footer = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("footer") as FooterContentType;

  return (
    <StyledFooter className="dark-bg" {...p}>
      <div className="top-section">
        <div className="section general">
          <img
            src={albourghLogo}
            alt="Heuver's logo with brand name"
            className="mb-24"
            loading="lazy"
          />
          <Typography tag="p" variant="mediumBody" className="mb-42">
            {content["brand_description"]}
          </Typography>
          <ul className="social-links">
            {socialLinks.map((socialLink, ix) => (
              <li key={ix}>
                <a href={socialLink.link} aria-label={socialLink.socialMedia}>
                  <Icon {...socialLink.icon} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="section contact">
          <Typography
            tag="p"
            variant="subtitle"
            color="landscapeGreen"
            className="mb-32"
          >
            {content["contact_label"]}
          </Typography>
          <a href="mailto:info@albourgh.com" className="email">
            <Icon {...Email} />
            {emailAddress}
          </a>
        </div>
      </div>
      <div className="divider"></div>
      <div className="bottom-section legal-info">
        <span className="links">
          <Typography tag="a" variant="smallBody" href="https://www.heuver.com/privacy-statement">
            {content["privacy_label"]}
          </Typography>
          <Typography tag="a" variant="smallBody" href="https://www.heuver.com/terms-and-conditions" className="ml-24">
            {content["disclaimer_label"]}
          </Typography>
          <Typography tag="a" variant="smallBody" href="https://www.heuver.com/privacy-statement" className="ml-24">
            {content["cookies_label"]}
          </Typography>
        </span>
      </div>
    </StyledFooter>
  );
};

export default Footer;
