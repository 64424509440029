import styled from "@emotion/styled";

export default styled.div`
  padding: 64px;
  width: 86vw;
  height: 70vh;
  max-height: 70vh;
  display: flex;
  column-gap: 64px;

  .energy-card {
    flex-basis: 380px;
    flex-shrink: 0;
    border: 1px solid rgba(28, 46, 52, 0.2);
    display: flex;
    flex-direction: column;

    .energy-img {
      padding: 48px;
      flex-grow: 2;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    a.download-card {
      ${({ theme }) => theme.typography.subtitle}
      color: ${({ theme }) => theme.colors.wetAsphalt};
      text-align: center;
      padding: 20px 0;
      border-top: 1px solid rgba(28, 46, 52, 0.2);
      transition: color 0.2s ease-in-out;

      &:hover {
        color: ${({ theme }) => theme.colors.wetAsphalt80};
      }
    }
  }

  .info-section {
    text-align: start;
    flex-grow: 2;
    overflow-y: auto;

    .product-name {
      display: flex;
      align-items: center;

      .green-bar {
        height: 8px;
        width: 70px;
        margin: 0 15px;
        background-color: ${({ theme }) => theme.colors.landscapeGreen};
      }
    }

    ul.product-features {
      margin-top: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 8px;
      }

      svg {
        margin-right: 8px;
      }
    }

    a.full.green {
      margin-bottom: 48px;
    }

    .table-data {
      padding: 0 20px 25px 20px;
      background-color: ${({ theme }) => theme.colors.white};

      .divider {
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.wetAsphalt};
        opacity: 0.2;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      .bottom-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;

        a.full.green {
          margin: 0;
        }

        .icons {
          display: flex;
          align-items: center;
          column-gap: 8px;

          .truck-icon {
            margin-left: 16px;
          }
        }
      }
    }

    table {
      width: 100%;
      text-align: start;
      border-collapse: separate;
      border-spacing: 0;
      background-color: ${({ theme }) => theme.colors.white};

      th {
        text-align: start;
      }

      th {
        padding: 18px 24px;
      }

      td {
        padding: 12px 24px;

        &.green {
          color: ${({ theme }) => theme.colors.landscapeGreen};
        }
      }

      thead th:nth-of-type(1) {
        width: 30%;
      }

      thead th:nth-of-type(2) {
        width: 50%;
      }

      thead th:nth-of-type(3) {
        width: 20%;
      }

      tbody tr:nth-of-type(odd) {
        background-color: ${({ theme }) => theme.colors.sandDust};
      }
    }
  }

  ${({ theme }) => theme.mediaDown.desktopSmall} {
    padding: 48px;
    height: 50vh;
    max-height: unset;

    .energy-card {
      flex-basis: 250px;
      .energy-img {
        padding: 24px;
      }
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    box-sizing: border-box;
    width: 100%;
    max-width: 450px;
    height: fit-content;
    align-items: flex-start;
    padding: 56px 15px;

    flex-direction: column-reverse;
    margin: 0 auto;

    .energy-card {
      width: 100%;
      margin-top: 32px;

      .energy-img {
        padding: 32px;
      }
    }

    .info-section {
      overflow-y: hidden;
      width: 100%;

      a.full.green {
        width: 100%;
      }

      .btn {
        width: 100%;
        text-align: center;
      }

      .table-data {
        padding: 0 10px 10px 10px;

        table {
          overflow-x: auto;

          th {
            padding: 9px 12px;
          }

          td {
            padding: 10px 12px;
          }
        }
      }
    }
  }
`;
