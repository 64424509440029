import styled from "@emotion/styled";

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 900;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .popup {
    position: relative;
    display: flex;
    ${({ theme }) => theme.backgrounds.sandDust};

    button.close-btn {
      position: absolute;
      top: 22px;
      right: 22px;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 50;

      svg {
        width: 14px;
        height: 14px;
        transition: fill 0.2s ease-in-out, transform 0.1s ease-in-out;
      }

      &:hover {
        svg {
          fill: ${({ theme }) => theme.colors.landscapeGreen};
        }
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    align-items: flex-start;
    overflow-y: auto;

    .popup {
      width: 100%;
      max-width: 100%;
      min-height: 100vh;
      overflow-y: auto;
    }
  }
`;
