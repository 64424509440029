import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledMoodmakerTile from "../../styles/pageparts/MoodmakerTile.style";

type MoodmakerContentType = {
  title: string;
  tag: string;
};

const MoodmakerTile = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("moodmaker_tile") as MoodmakerContentType;

  return (
    <StyledMoodmakerTile className="container" {...p}>
      <div className="tile white-bg">
        <div className="tag">{content["tag"]}</div>
        <h3 dangerouslySetInnerHTML={{ __html: content["title"] }}></h3>
      </div>
    </StyledMoodmakerTile>
  );
};

export default MoodmakerTile;
