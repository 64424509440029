import PropTypes from "prop-types";
import React from "react";
import { Theme } from "../../theme/Theme";
import StyledTypography from "../../styles/units/Typography.style";

const Typography = ({
  tag = "div",
  variant = "body",
  color,
  bold = false,
  ...p
}: {
  tag?: string;
  variant?: string;
  color?: string;
  bold?: boolean;
  className?: string;
  href?: string;
  children?: React.ReactNode;
}) => {
  return <StyledTypography as={tag} variant={variant} color={color} bold={bold} {...p} />;
};

Typography.propTypes = {
  variant: PropTypes.oneOf(Object.keys(Theme.typography)),
  tag: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "span",
    "div",
    "p",
    "li",
    "ul",
    "pre",
    "a",
  ]),
  color: PropTypes.oneOf(Object.keys(Theme.colors)),
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
};

export default Typography;
