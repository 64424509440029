import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetText } from "../../languages/LanguageProvider";
import StyledContactPopup from "../../styles/globals/ContactPopup.style";
import StyledForm from "../../styles/units/Form.style";
import { emailAddress, socialLinks } from "../../utils/globalContent";
import { ChevronRight, Email } from "../../utils/icons";
import Icon from "../units/Icon";
import Typography from "../units/Typography";
import Button from "../units/Button";
import { imagesUrl } from "../../utils/globalContent";
import { usePopupContext } from "../../utils/PopupContextProvider";

type FormFieldType = {
  label: string;
  error_msg: string;
};

type ContactPopupContentType = {
  info: {
    title: string;
    description: string;
  };
  form: {
    title: string;
    company_input: FormFieldType;
    firstname_input: FormFieldType;
    lastname_input: FormFieldType;
    phone_number_input: FormFieldType;
    email_input: FormFieldType;
    message_input: FormFieldType;
    conditions_checkbox: FormFieldType;
    button_label: string;
    required_field: string;
  };
  submission: {
    title: string;
    description: string;
    button_label: string;
  };
};

const ContactPopup = ({ ...p }: { className?: string }) => {
  const content = GetText("contact_popup") as ContactPopupContentType;
  const contentInfo = content["info"];
  const contentForm = content["form"];
  const contentSubmission = content["submission"];

  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const { handlePopupState } = usePopupContext();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formik = useFormik({
    initialValues: {
      companyName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      message: "",
      termsAndConditions: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string(),
      firstName: Yup.string().required(contentForm['required_field']),
      lastName: Yup.string().required(contentForm['required_field']),
      phoneNumber: Yup.string().matches(
        phoneRegExp,
        contentForm["phone_number_input"]["error_msg"]
      ),
      emailAddress: Yup.string()
        .required(contentForm['required_field'])
        .email(contentForm["email_input"]["error_msg"]),
      message: Yup.string().required(contentForm['required_field']),
      termsAndConditions: Yup.boolean().isTrue(
        "You must agree to the terms and conditions"
      ).required(contentForm['required_field']),
    }),
    onSubmit: (values, { resetForm }) => {
      fetch("https://hooks.zapier.com/hooks/catch/14378197/bvizh3t/", {
        method: "POST",
        body: JSON.stringify(values),
      })
        .then((response) => {
          if (response.status === 200) {
            setSuccessfulSubmit(true);
            resetForm();
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
  });

  const setInput = (
    id: string,
    label: string,
    type?: string,
    required?: boolean,
    className?: string,
    Tag: string = "input"
  ) => {
    return (
      <div
        className={`styled-input ${className} ${
          // @ts-ignore
          formik.touched[id] && formik.errors[id] && "error"
        } ${
          // @ts-ignore
          formik.values[id] && "has-value"
        }
        `}
      >
        <label htmlFor={id} className="styled-label">
          {label}
        </label>
        <Tag
          // @ts-ignore
          id={id}
          type={type}
          required={required}
          rows={Tag === "textarea" ? 5 : null}
          {...formik.getFieldProps(id)}
        />
        {/* @ts-ignore */}
        {formik.touched[id] && formik.errors[id] ? (
          // @ts-ignore
          <div className="error-msg">{formik.errors[id]}</div>
        ) : null}
      </div>
    );
  };

  return (
    <StyledContactPopup {...p}>
      <div className="popup-contact-section">
        <Typography tag="h4" variant="heading4" bold={true} className="mb-32">
          {contentInfo["title"]}
        </Typography>
        <Typography tag="p" variant="mediumBody" className="mb-42">
          {contentInfo["description"]}
        </Typography>
        <div className="divider mb-42"></div>
        <a href="mailto:info@albourgh.com" className="email mb-42">
          <Icon {...Email} />
          {emailAddress}
        </a>
        <ul className="social-links">
          {socialLinks.map((socialLink, ix) => (
            <li key={ix}>
              <a href={socialLink.link} aria-label={socialLink.socialMedia}>
                <Icon {...socialLink.icon} />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="popup-form-section">
        <Typography
          tag="h4"
          variant="heading4"
          bold={true}
          className="form-title mb-32"
        >
          {contentForm["title"]}
        </Typography>
        <StyledForm onSubmit={formik.handleSubmit}>
          {setInput("companyName", contentForm["company_input"].label, "text")}
          {setInput(
            "firstName",
            contentForm["firstname_input"].label,
            "text",
            true,
            "half-width"
          )}
          {setInput(
            "lastName",
            contentForm["lastname_input"].label,
            "text",
            true,
            "half-width"
          )}
          {setInput(
            "phoneNumber",
            contentForm["phone_number_input"].label,
            "tel",
            undefined,
            "half-width"
          )}
          {setInput(
            "emailAddress",
            contentForm["email_input"].label,
            "email",
            true,
            "half-width"
          )}
          {setInput(
            "message",
            contentForm["message_input"].label,
            undefined,
            true,
            undefined,
            "textarea"
          )}
          <label htmlFor="termsAndConditions" className="checkbox-wrapper">
            <input
              id="termsAndConditions"
              type="checkbox"
              {...formik.getFieldProps("termsAndConditions")}
            />
            {contentForm["conditions_checkbox"]["label"]}&nbsp;
            <a
              href="https://www.heuver.com/terms-and-conditions"
              className="terms"
              target="_blank"
              rel="noreferrer"
            >
              {/* @ts-ignore */}
              {contentForm["conditions_checkbox"]["terms_link_text"]}
            </a>
            {/* @ts-ignore */}
            {formik.touched["termsAndConditions"] &&
            formik.errors["termsAndConditions"] ? (
              // @ts-ignore
              <div className="error-msg">
                {formik.errors["termsAndConditions"]}
              </div>
            ) : null}
          </label>
          <Button icon={ChevronRight} type="submit" tag="button">
            {contentForm["button_label"]}
          </Button>
        </StyledForm>
      </div>
      <div
        className={`successful-submission ${successfulSubmit ? "active" : ""}`}
      >
        <img
          className="background"
          src={`${imagesUrl}shutterstock_2016196853+copy+2+-+CMYK.jpg`}
          alt="Landscape"
          loading="lazy"
        />
        <Typography
          tag="h4"
          variant="heading4"
          color="white"
          bold={true}
          className="form-title mb-32"
        >
          {contentSubmission["title"]}
        </Typography>
        <Typography
          tag="p"
          variant="mediumBody"
          color="white"
          className="mb-42"
        >
          {contentSubmission["description"]}
        </Typography>
        <Button
          icon={ChevronRight}
          tag="button"
          onCustomClick={() => {
            handlePopupState();
            window.scrollTo(0, 0);
            setSuccessfulSubmit(false);
          }}
        >
          {contentSubmission["button_label"]}
        </Button>
      </div>
    </StyledContactPopup>
  );
};

export default ContactPopup;
