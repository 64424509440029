import { css } from "@emotion/react";
import styled from "@emotion/styled";

type HotSpotProps = {
  position: string;
  left: number;
  disabled?: boolean;
};

export default styled.div<HotSpotProps>`
  position: absolute;
  z-index: 100;
  ${({ position }) =>
    css`
      ${position}
    `}

  .hotspot {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.wetAsphalt80};
    opacity: 0.8;
    transition: transform 0.15s ease-in-out, background-color 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 48px;
      height: 48px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.colors.lightGrey};
      opacity: 0.4;
      border-radius: 100%;
    }

    &.active {
      transform: rotate(45deg);

      & + .hotspot-content {
        opacity: 1;
        pointer-events: auto;
      }
    }

    ${({ theme, disabled }) =>
      !disabled &&
      css`
        cursor: pointer;
        background-color: ${theme.colors.white};
        opacity: 1;

        &::before {
          background-color: ${theme.colors.white};
        }

        &:hover {
          transform: scale(0.95);
        }
      `}
  }

  .hotspot-content {
    position: absolute;
    display: flex;
    height: 115px;
    min-width: 350px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;

    ${({ left }) => css`
      transform: translate(-${left}%, 25px);
    `}

    .icon {
      background-color: ${({ theme }) => theme.colors.wetAsphalt80};
      width: 115px;
      height: 115px;
      min-width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      padding: 24px;
      text-align: start;
    }

    &::before {
      display: none;
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: -4px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      z-index: 1;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme }) => theme.media.desktopSmall} {
    .hotspot-content {
      left: 50%;
      transform: translate(-50%, 25px);

      &::before {
        display: inline;
      }
    }
  }
`;
