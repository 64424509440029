import { useEffect, useRef, useState } from "react";
import {
  availableLanguages,
  useLanguageContext,
} from "../../languages/LanguageProvider";
import StyledLanguageSwitcher from "../../styles/units/LanguageSwitcher.style";
import Icon from "./Icon";
import { Checked, Globe, TriangleDown, Unchecked } from "../../utils/icons";
import Typography from "./Typography";
import { baseUrl } from "../../utils/globalContent";

const LanguageSwitcher = ({ ...p }: { className?: string }) => {
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();

  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = useState(false);
  const languageSwitcherRef = useRef(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // @ts-ignore
      if (languageSwitcherRef.current && languageSwitcherRef.current.contains(e.target)) return;
      setIsLanguagePopupOpen(false)
    };

    window.addEventListener("click", handleClick);

    return () => window.removeEventListener("click", handleClick);
  }, []);

  return (
    <StyledLanguageSwitcher className="language-switcher">
      <div
        className="current-language"
        onClick={() => setIsLanguagePopupOpen(!isLanguagePopupOpen)}
        ref={languageSwitcherRef}
      >
        <Icon {...Globe} fill="white" className="globe-icon" />
        <Typography
          variant="smallBody"
          color="white"
          className="active-language"
        >
          {availableLanguages[currentLanguage]}
        </Typography>
        <Icon {...TriangleDown} fill="white" className="triangle-icon" />
      </div>
      <ul
        className={`available-languages ${isLanguagePopupOpen ? "active" : ""}`}
      >
        {Object.entries(availableLanguages).map(([key, value], ix) => (
          <li
            key={ix}
            data-language={key}
            className={`${key === currentLanguage ? "active" : ""}`}
            onClick={() => setCurrentLanguage(key)}
          >
            <Icon
              {...(key === currentLanguage ? Checked : Unchecked)}
              fill={`${key === currentLanguage ? "" : "lightGrey"}`}
            />
            <img src={`${baseUrl}icons/${key}.svg`} alt={`${value} flag`} />
            <Typography variant="subtitle">{value}</Typography>
          </li>
        ))}
      </ul>
    </StyledLanguageSwitcher>
  );
};

export default LanguageSwitcher;
