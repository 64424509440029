import styled from "@emotion/styled";

export default styled.section`
  padding-top: 100px;
  padding-bottom: 200px;

  .txt-and-img {
    position: relative;
    width: 100%;
    height: 80vh;
    background-color: ${({ theme }) => theme.colors.rubberBlack};

    img.brand-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.9;
    }

    .text-section {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding: 0 115px;
      max-width: 60%;
      height: fit-content;
    }
  }

  .decorative-items {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    .tire {
      position: absolute;
      bottom: 50%;
      right: 0;
      transform: translate(38%, 50%);
      z-index: 10;
      width: 30vw;
      max-width: 420px;
    }

    .pattern {
      position: relative;
      top: 50%;
      transform: translateX(38%);
    }
  }

  ${({ theme }) => theme.mediaDown.desktopSmall} {
    .txt-and-img {
      height: 66vh;

      .text-section {
        max-width: 50%;
      }
    }

    .decorative-items {
      .tire {
        width: 35vw;
        bottom: -15%;
        transform: translate(0);
      }

      .pattern {
        transform: translateX(-8%);
        top: 75%;
      }
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    padding-top: 60px;
    padding-bottom: 120px;

    .txt-and-img {
      height: 55vh;

      .text-section {
        max-width: 100%;
        padding: 32px;
        top: 0;
        transform: translate(0);

        p {
          display: none;
        }
      }
    }

    .decorative-items {
      .tire {
        width: 210px;
        transform: translateX(8%);
      }

      .pattern {
        top: 85%;
      }
    }
  }
`;
