import AboutUs from "./components/pageparts/AboutUs";
import BrandExperienceWImg from "./components/pageparts/BrandExperienceWImg";
import ContactUs from "./components/pageparts/ContactUs";
import HeaderWVideo from "./components/pageparts/HeaderWVideo";
import InnovativeTechniquesTabs from "./components/pageparts/InnovativeTechniquesTabs";
import MoodmakerTile from "./components/pageparts/MoodmakerTile";
import ProductsSlider from "./components/pageparts/ProductsSlider";
import UspsGridSlider from "./components/pageparts/UspsGridSlider";

const AlbourghPage = () => {
  return (
    <>
      <HeaderWVideo></HeaderWVideo>
      <AboutUs></AboutUs>
      <UspsGridSlider></UspsGridSlider>
      <ProductsSlider></ProductsSlider>
      <BrandExperienceWImg></BrandExperienceWImg>
      <InnovativeTechniquesTabs></InnovativeTechniquesTabs>
      <MoodmakerTile></MoodmakerTile>
      <ContactUs></ContactUs>
    </>
  );
};

export default AlbourghPage;