import styled from "@emotion/styled";

export default styled.section`
  padding: 100px 48px 20px 48px;

  a {
    transition: color 0.2s ease-in-out;
  }

  a:hover {
    color: #77e6a9;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    ${({ theme }) => theme.media.laptop} {
      margin-bottom: 80px;
    }

    .section {
      width: 22vw;
      margin-right: 10vw;

      &.contact > a.email {
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.wetAsphalt80};
    margin-bottom: 20px;

    ${({ theme }) => theme.mediaDown.laptop} {
      display: none;
    }
  }

  .bottom-section.legal-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    a {
      text-decoration: underline;
    }

    img {
      height: 20px;
      width: 100px;
      object-fit: contain;
      margin-right: 12px;
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    padding: 60px 15px 24px 15px;

    .top-section {
      flex-direction: column;

      .section {
        width: 100%;
        margin: 0;
        padding-bottom: 36px;
        border-bottom: solid 1px ${({ theme }) => theme.colors.wetAsphalt80};

        &.contact {
            padding-top: 36px;
        }
      }
    }

    .bottom-section.legal-info {
      justify-content: flex-start;

      .links {
        padding-top: 24px;
      }
    }
  }
`;
