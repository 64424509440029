import styled from "@emotion/styled";

export default styled.div`
  position: relative;
  overflow: hidden;

  .next-swiper-btn,
  .prev-swiper-btn {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: ${({ theme }) => theme.colors.white};
    }

    &.swiper-button-disabled {
      pointer-events: none;
      svg {
        fill: rgba(255, 255, 255, 0.2);
      }
    }

    &.dark {
      border: 1px solid rgba(20, 23, 26, 0.2);

      svg {
        fill: ${({ theme }) => theme.colors.wetAsphalt};
      }

      &.swiper-button-disabled {
        svg {
          fill: rgba(20, 23, 26, 0.2);
        }
      }

      &:hover {
        border-color: ${({ theme }) => theme.colors.wetAsphalt};
      }
    }

    &.pagination {
      ${({ theme }) => theme.mediaDown.desktopSmall} {
        top: unset;
        bottom: 0;
      }
    }
  }

  .prev-swiper-btn {
    left: 15px;

    &.default {
      ${({ theme }) => theme.media.desktopSmall} {
        left: 22vw;
      }
    }

    &.pagination {
      ${({ theme }) => theme.media.desktopSmall} {
        left: 48px;
      }
    }
  }

  .next-swiper-btn {
    right: 15px;

    &.default {
      ${({ theme }) => theme.media.desktopSmall} {
        right: 22vw;
      }
    }

    &.pagination {
      ${({ theme }) => theme.media.desktopSmall} {
        right: 48px;
      }
    }
  }

  ${({ theme }) => theme.media.desktopSmall} {
    .swiper.pagination {
      margin-bottom: 100px;
    }
  }

  // Small screen sizes pagination
  .pagination-count {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
  }

  // Large screen sizes pagination
  .pagination-items {
    ${({ theme }) => theme.paddingStyle}
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 27px;

    .pagination-item {
      position: relative;
      margin: 0;
      flex-basis: 18vw;
      flex-grow: 2;
      padding-top: 20px;
      overflow: hidden;

      p {
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
      }

      &::before {
        background-color: ${({ theme }) => theme.colors.white};
      }

      &::after {
        background-color: ${({ theme }) => theme.colors.landscapeGreen};
        transform: translateX(-110%);
        transition: transform 0.3s ease-in-out;
      }

      &.active {
        p {
          opacity: 1;
        }

        &::after {
          transform: translateX(0);
        }
      }
    }
  }
`;
