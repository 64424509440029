import styled from "@emotion/styled";

export default styled.div`
  display: flex;
  z-index: 100;

  .current-language {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    margin-left: auto;
  }

  .globe-icon {
    margin-right: 5px;
  }

  .active-language {
    margin-right: 10px;
    line-height: unset;
  }

  ul.available-languages {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin: 0;
    padding: 32px;
    ${({ theme }) => theme.backgrounds.white}
    list-style: none;
    pointer-events: none;
    transform: translateY(110%);
    opacity: 0;
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }

    li {
      color: ${({ theme }) => theme.colors.wetAsphalt};
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;

      &.active {
        cursor: default;
      }

      svg {
        transition: fill 0.3s ease-in-out;
      }

      &:hover {
        svg {
          fill: ${({ theme }) => theme.colors.wetAsphalt};
        }
      }
    }
  }

  ${({ theme }) => theme.media.desktopSmall} {
    position: relative;

    ul.available-languages {
      width: fit-content;
      left: unset;
      bottom: unset;
      top: 100%;
      margin-top: 8px;
      transform: none;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.active {
        opacity: 1;
      }
    }
  }
`;
