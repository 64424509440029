import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledContactUs from "../../styles/pageparts/ContactUs.style";
import { ChevronRight } from "../../utils/icons";
import Button from "../units/Button";
import {
  availablePopupTypes,
  usePopupContext,
} from "../../utils/PopupContextProvider";
import { imagesUrl } from "../../utils/globalContent";

type ContactUsContentType = {
  title: string;
  description: string;
  button_label: string;
};

const ContactUs = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("contact_us") as ContactUsContentType;

  const { setPopupContent, handlePopupState } = usePopupContext();

  return (
    <StyledContactUs className="container dark-bg" {...p}>
      <div className="contact-section light-bg">
        <h3 dangerouslySetInnerHTML={{ __html: content["title"] }}></h3>
        <p className="contact-txt">{content["description"]}</p>
        <Button
          tag="button"
          icon={ChevronRight}
          // @ts-ignore
          onClick={() => {
            setPopupContent(availablePopupTypes.CONTACT);
            handlePopupState();
          }}
        >
          {content["button_label"]}
        </Button>
        <img
          src={`${imagesUrl}tire-pattern.svg`}
          alt="Tire pattern outlined"
          className="tire-pattern"
        />
      </div>
    </StyledContactUs>
  );
};

export default ContactUs;
