import styled from "@emotion/styled";

export default styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: end;
  background-image: url("./concept-img-front-tyre.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;

  div.tile {
    ${({ theme }) => theme.backgrounds.white};
    padding: 64px 56px;
    width: 40vw;

    h3 {
      margin: 0;
    }
  }

  ${({ theme }) => theme.mediaDown.dekstopBig} {
    div.tile {
      width: 45vw;
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    height: 90vh;
    align-items: end;
    padding-bottom: 20px;

    div.tile {
      width: 100%;
      padding: 32px;
    }
  }
`;
