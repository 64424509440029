import React, { useContext, useEffect, useMemo, useRef } from "react";
import { createContext, useState } from "react";
import { Theme } from "../theme/Theme";

const breakpoints = Theme.breakpoints.map((elm) => {
    const {name, size} = elm;
    return {name, size};
});

class Breakpoint {
    private name: string;
    private readonly breakpoints: string[] = [...breakpoints.map(elm => elm?.name), "mobile"];

    constructor(name: string) {
        this.name = name;
    }

    isUp(name: string) {
        return this.breakpoints.indexOf(name) >= this.breakpoints.indexOf(this.name);
    }

    isDown(name: string) {
        return this.breakpoints.indexOf(name) <= this.breakpoints.indexOf(this.name);
    }

    setCurrentBreakpoint(name: string) {
        this.name = name;
    }

    getCurrentBreakpoint() {
        return this.name;
    }
}

const getBreakpoint = (val: number) => {
    return breakpoints.find(elm => elm.size < val)?.name || "mobile";
}

const BreakpointContext = createContext<Breakpoint>(new Breakpoint("desktop"));

export const BreakpointContextProvider = ({
    children
}: {
    children?: React.ReactNode
}) => {
    const [breakpoint, setBreakpoint] = useState<string>("desktop");
    const breakpointRef = useRef<string | undefined>();
    breakpointRef.current = breakpoint;

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            const newBreakpoint = getBreakpoint(Math.min(window.innerWidth, window.screen.width));
            if (newBreakpoint !== breakpointRef.current) {
                setBreakpoint(newBreakpoint);
            }
        }

        // Listen to window resize
        window.addEventListener('resize', handleResize);

        // Call once at app start
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const breakpointObj = useMemo(() => new Breakpoint(breakpoint), [breakpoint]);

    return (
        <BreakpointContext.Provider value={breakpointObj}>
            {children}
        </BreakpointContext.Provider>
    );
}

export const useBreakpointContext = () => {
    return useContext(BreakpointContext);
}