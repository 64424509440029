import styled from "@emotion/styled";

export default styled.div`
  display: flex;
  text-align: start;

  .popup-contact-section {
    display: flex;
    flex-direction: column;
    max-width: 28vw;
    padding: 48px;
    ${({ theme }) => theme.backgrounds.wetAsphalt};

    .divider {
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.wetAsphalt80};
    }

    a.email {
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }
    }

    .social-links {
      margin-top: auto;
    }

    ${({ theme }) => theme.mediaDown.laptop} {
      display: none;
    }
  }

  .popup-form-section {
    width: 40vw;
    padding: 48px;

    a.terms {
      color: ${({ theme }) => theme.colors.wetAsphalt};
      text-decoration: underline;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: ${({ theme }) => theme.colors.landscapeGreen};
      }
    }

    ${({ theme }) => theme.mediaDown.laptop} {
      width: 100%;
      padding: 75px 15px;

      .form-title {
        margin-bottom: 24px;
      }
    }
  }

  .successful-submission {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #14171a;
    transition: opacity 0.3s ease-in-out;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    p {
      max-width: 50%;
    }

    img.background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
      z-index: -1;
    }
  }
`;
