import React, { useState } from "react";
import StyledSwiperLayout from "../../styles/layouts/SwiperLayout.style";
import { Swiper, SwiperSlide } from "swiper/react";
import { useBreakpointContext } from "../../utils/BreakpointContextProvider";
import { Navigation, EffectCreative } from "swiper";
import Icon from "../units/Icon";
import { ArrowLeft, ArrowRight } from "../../utils/icons";
import Typography from "../units/Typography";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-creative";

type SwiperEffectType =
  | "creative"
  | "slide"
  | "fade"
  | "cube"
  | "coverflow"
  | "flip"
  | "cards"
  | undefined;

export enum SwiperScreenSize {
  ALWAYS,
  MOBILE,
}

export enum SwiperType {
  DEFAULT = "default",
  SLIDE_OVER = "slide_over",
  PAGINATION = "pagination",
}

const SwiperLayout = ({
  children,
  swiperScreenSize = SwiperScreenSize.ALWAYS,
  swiperType = SwiperType.DEFAULT,
  breakpoint = "laptop",
  swiperBtnStyle = "light", // dark or light
  paginationItems,
  updateCurrentIndex,
  className, // A unique classname for each slider to work independently
  ...p
}: {
  children: JSX.Element | JSX.Element[];
  swiperScreenSize?: SwiperScreenSize;
  swiperType?: SwiperType;
  breakpoint?: string;
  swiperBtnStyle?: string;
  paginationItems?: Array<string>;
  updateCurrentIndex?: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
}) => {
  const smallScreenSize = useBreakpointContext().isDown(breakpoint);

  const [currentIndex, setCurrentIndex] = useState(0);

  const defaultSliderOptions = {
    slidesPerView: 1,
    navigation: {
      prevEl: `.prev-${className}`,
      nextEl: `.next-${className}`,
    },
    allowTouchMove: true,
    breakpoints: {
      // Laptop breakpoint
      769: {
        allowTouchMove: false,
      },
    },
  };

  const options = {
    default: {
      centeredSlides: true,
      speed: 600,
      breakpoints: {
        // Laptop breakpoint
        769: {
          slidesPerView: 2,
        },
      },
      effect: "creative" as SwiperEffectType,
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: ["-100%", 0, -1],
          opacity: 0.5,
          scale: 1.1,
        },
        next: {
          translate: ["100%", 0, -1],
          opacity: 0.5,
          scale: 1.1,
        },
      },
      modules: [EffectCreative, Navigation],
    },
    slide_over: {
      effect: "creative" as SwiperEffectType,
      creativeEffect: {
        prev: {
          translate: [0, 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
      speed: 500,
      modules: [EffectCreative, Navigation],
    },
    pagination: {
      speed: 500,
      modules: [Navigation],
    },
  };

  /**
   * Setup swiper's prev slide button.
   */
  const prevSlideBtn = () => {
    return (
      <div
        className={`prev-swiper-btn prev-${className} ${swiperType} ${swiperBtnStyle}`}
      >
        <Icon {...ArrowLeft} />
      </div>
    );
  };

  /**
   * Setup swiper's next button.
   */
  const nextSlideBtn = () => {
    return (
      <div
        className={`next-swiper-btn next-${className} ${swiperType} ${swiperBtnStyle}`}
      >
        <Icon {...ArrowRight} />
      </div>
    );
  };

  /**
   * If pagination mode (Innovative techniques pagepart), setup pagination.
   */
  const pagination = (index: number) => {
    return (
      <>
        {smallScreenSize ? (
          <Typography tag="p" variant="heading5" className="pagination-count">
            {index + 1} OF 4
          </Typography>
        ) : (
          <div className="pagination-items">
            {paginationItems!.map((item, ix) => (
              <Typography
                tag="div"
                variant="heading5"
                color="wetAsphalt"
                key={ix}
                className={`${index === ix ? "active" : ""} pagination-item`}
              >
                <p>{item}</p>
              </Typography>
            ))}
          </div>
        )}
      </>
    );
  };

  const swiperLayout = () => (
    <StyledSwiperLayout
      className={`swiper-layout ${swiperType} ${className}`}
      {...p}
    >
      {prevSlideBtn()}
      <Swiper
        {...options[swiperType]}
        {...defaultSliderOptions}
        onActiveIndexChange={(swiper) => {
          setCurrentIndex(swiper.realIndex);
          if (updateCurrentIndex) {
            updateCurrentIndex(swiper.realIndex);
          }
        }}
        className={swiperType}
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
      {nextSlideBtn()}
      {swiperType === SwiperType.PAGINATION && pagination(currentIndex)}
    </StyledSwiperLayout>
  );

  return (
    <>
      {swiperScreenSize === SwiperScreenSize.ALWAYS ||
      (swiperScreenSize === SwiperScreenSize.MOBILE && smallScreenSize)
        ? swiperLayout()
        : children}
    </>
  );
};

export default SwiperLayout;
