import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const buttonStyle = ({
  theme
}: {
  theme?: any
}) => css`
  cursor: pointer;
  display: inline-flex;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  align-items: unset;

  .btn {
    position: relative;
    overflow: hidden;
    padding: 20px;
    ${theme.typography.subtitle}
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 150%;
      height: 0;
      padding-bottom: 120%;
      z-index: -1;
      clip-path: polygon(0% 100%, 0% 100%, 0% 100%);
      transition: clip-path 0.4s ease-in-out;
    }
  }

  &.full {
    .btn-icon {
      margin-left: 2px;
      padding-left: 22px;
      padding-right: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.1s ease-in-out;
    }
  }

  &:hover {
    .btn::after {
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    }
  }

  &.green {
    .btn {
      ${theme.backgrounds.landscapeGreen}

      &::after {
        background-color: ${theme.colors.darkGreen};
      }
    }

    &.full {
      .btn-icon {
        ${theme.backgrounds.landscapeGreen}
      }
    }
  }

  &.semi-transparent {
    .btn {
      ${theme.backgrounds.semiTransparent}

      &::after {
        background-color: #5C5C5C;
      }
    }

    &.full {
      .btn-icon {
        ${theme.backgrounds.semiTransparent}
      }
    }
  }

  &.transparent {
    .btn {
      border: 1px solid rgba(255, 255, 255, 0.4);
      color: ${theme.colors.white};

      &::after {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }

  &:active {
    &.full.green > .btn-icon {
      background-color: ${theme.colors.darkGreen};
    }

    &.full.semi-transparent > .btn-icon {
      background-color: #5C5C5C;
    }
  }
`;

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(
  ({ as: T = "div", ...props }: { as: string, children?: React.ReactNode }, ref) => (
    // @ts-ignore
    <T {...props} ref={ref} />
  )
);


export default styled(Component)`
  ${buttonStyle}
`;