import React, { useEffect, useRef, useState } from "react";
import { SerializedStyles } from "@emotion/react";
import StyledHotSpot from "../../styles/units/HotSpot.style";
import { Plus } from "../../utils/icons";
import Icon from "./Icon";
import Typography from "./Typography";

const HotSpot = ({
  top,
  left,
  iconSrc,
  disabled = false,
  children,
  ...p
}: {
  top: number;
  left: number;
  iconSrc: string | null;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  css?: SerializedStyles;
  style?: any;
}) => {
  const [isActive, setIsActive] = useState(false);

  const hotspotRef = useRef(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // @ts-ignore
      if (hotspotRef.current?.contains(e.target)) return;

      setIsActive(false);
    };

    window.addEventListener("click", handleClick);

    return () => window.removeEventListener("click", handleClick);
  }, []);

  const handleHotspotOpening = () => {
    if (disabled) return;

    setIsActive(!isActive);
  };

  const position = `top: ${top}%; left: ${left}%;`;

  return (
    <StyledHotSpot position={position} left={left} disabled={disabled} {...p}>
      <div
        className={`hotspot${isActive ? " active" : ""}`}
        ref={hotspotRef}
        onClick={handleHotspotOpening}
      >
        <Icon {...Plus} fill={`${disabled ? "lightGrey" : "landscapeGreen"}`} />
      </div>
      <div className="hotspot-content">
        {iconSrc && (
          <div className="icon">
            <img src={iconSrc} alt="Default icon" loading="lazy" />
          </div>
        )}
        <Typography
          tag="p"
          variant="smallBody"
          color="wetAsphalt"
          className="text"
        >
          {children}
        </Typography>
      </div>
    </StyledHotSpot>
  );
};

export default HotSpot;
