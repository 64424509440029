import styled from "@emotion/styled";

export default styled.section`
  padding-top: 200px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;

  .contact-section {
    position: relative;
    padding: 80px 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .contact-txt {
      margin-bottom: 34px;
      max-width: 660px;
    }

    .tire-pattern {
      position: absolute;
      top: -18%;
      right: 0;
      transform: translateX(38%);
      z-index: -1;
    }
  }

  ${({ theme }) => theme.mediaDown.desktopSmall} {
    .contact-section > .tire-pattern {
      transform: translateX(-8%);
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    padding-top: 120px;
    padding-bottom: 60px;

    .contact-section {
      padding: 32px;

      .tire-pattern {
        display: none;
      }
    }
  }
`;
