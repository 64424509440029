import { iconsUrl, imagesUrl } from "./globalContent";

type Coordinates = {
  x: number;
  y: number;
};

type TableRow = {
  size: string;
  index: string;
  availability: string;
};

type ProductDataType = {
  product: string;
  productPicSrc: string;
  energyImgSrc: string;
  hotspotPositions: Array<Coordinates>;
  hotspotIcons: Array<string | null>;
  tableData: Array<TableRow>;
};

type ProductsType = Array<ProductDataType>;

export const productData: ProductsType = [
  {
    product: "S",
    productPicSrc: `${imagesUrl}Across-S.png`,
    energyImgSrc: `${imagesUrl}energy-S.webp`,
    hotspotPositions: [
      {
        x: 30,
        y: 20,
      },
      {
        x: 65,
        y: 30,
      },
      {
        x: 40,
        y: 65,
      },
    ],
    hotspotIcons: [
      null,
      `${iconsUrl}MS-hotspot.svg`,
      null,
    ],
    tableData: [
      {
        size: "205/75R17.5",
        index: "124/122M",
        availability: "Q3 2023",
      },
      {
        size: "215/75R17.5",
        index: "126/124M",
        availability: "Q3 2023",
      },
      {
        size: "225/75R17.5",
        index: "129/127M",
        availability: "Q3 2023",
      },
      {
        size: "235/75R17.5",
        index: "132/130M",
        availability: "Q3 2023",
      },
      {
        size: "245/70R17.5",
        index: "136/134M",
        availability: "Q3 2023",
      },
      {
        size: "265/70R19.5",
        index: "140/138M",
        availability: "Q3 2023",
      },
      {
        size: "285/70R19.5",
        index: "146/144L (145/143M)",
        availability: "Q3 2023",
      },
      {
        size: "275/70R22.5",
        index: "150/148M",
        availability: "Q2 2023",
      },
      {
        size: "295/60R22.5",
        index: "150/147K (149/146L)",
        availability: "now",
      },
      {
        size: "295/80R22.5",
        index: "154/149M",
        availability: "now",
      },
      {
        size: "315/60R22.5",
        index: "154/150L (152/148M)",
        availability: "Q2 2023",
      },
      {
        size: "315/70R22.5",
        index: "156/150L",
        availability: "now",
      },
      {
        size: "315/80R22.5",
        index: "156/150L (154/150M)",
        availability: "now",
      },
      {
        size: "385/55R22.5",
        index: "160K (158L)",
        availability: "now",
      },
      {
        size: "385/65R22.5",
        index: "164K (158L)",
        availability: "now",
      },
    ],
  },
  {
    product: "D",
    productPicSrc: `${imagesUrl}Across-D.png`,
    energyImgSrc: `${imagesUrl}energy-D.webp`,
    hotspotPositions: [
      {
        x: 50,
        y: 20,
      },
      {
        x: 30,
        y: 50,
      },
      {
        x: 65,
        y: 65,
      },
    ],
    hotspotIcons: [
      null,
      null,
      null,
    ],
    tableData: [
      {
        size: "205/75R17.5",
        index: "124/122M",
        availability: "Q3 2023",
      },
      {
        size: "215/75R17.5",
        index: "126/124M",
        availability: "Q3 2023",
      },
      {
        size: "225/75R17.5",
        index: "129/127M",
        availability: "Q3 2023",
      },
      {
        size: "235/75R17.5",
        index: "132/130M",
        availability: "Q3 2023",
      },
      {
        size: "245/70R17.5",
        index: "136/134M",
        availability: "Q3 2023",
      },
      {
        size: "265/70R19.5",
        index: "140/138M",
        availability: "Q3 2023",
      },
      {
        size: "285/70R19.5",
        index: "146/144L (145/143M)",
        availability: "Q3 2023",
      },
      {
        size: "295/60R22.5",
        index: "150/147K (149/146L)",
        availability: "now",
      },
      {
        size: "295/80R22.5",
        index: "152/148M",
        availability: "now",
      },
      {
        size: "315/60R22.5",
        index: "152/148L",
        availability: "Q2 2023",
      },
      {
        size: "315/70R22.5",
        index: "154/150L",
        availability: "now",
      },
      {
        size: "315/80R22.5",
        index: "156/150L (154/150M)",
        availability: "now",
      },
    ],
  },
  {
    product: "T",
    productPicSrc: `${imagesUrl}Across-T.png`,
    energyImgSrc: `${imagesUrl}energy-T.webp`,
    hotspotPositions: [
      {
        x: 50,
        y: 10,
      },
      {
        x: 40,
        y: 40,
      },
      {
        x: 60,
        y: 70,
      },
    ],
    hotspotIcons: [
      null,
      null,
      `${iconsUrl}MS-hotspot.svg`,
    ],
    tableData: [
      {
        size: "215/75R17.5",
        index: "136/134J",
        availability: "Q2 2023",
      },
      {
        size: "235/75R17.5",
        index: "143/141J (145/145F)",
        availability: "Q2 2023",
      },
      {
        size: "245/70R17.5",
        index: "143/141J (146/146F)",
        availability: "Q2 2023",
      },
      {
        size: "265/70R19.5",
        index: "143/141J (146/146F)",
        availability: "Q3 2023",
      },
      {
        size: "285/70R19.5",
        index: "150/148J",
        availability: "Q3 2023",
      },
      {
        size: "385/55R19.5",
        index: "156J",
        availability: "Q2 2023",
      },
      {
        size: "385/55R22.5",
        index: "160K",
        availability: "now",
      },
      {
        size: "385/65R22.5",
        index: "164K",
        availability: "now",
      },
      {
        size: "425/65R22.5",
        index: "165K",
        availability: "Q2 2023",
      },
      {
        size: "445/65R22.5",
        index: "169K",
        availability: "Q3 2023",
      },
    ],
  },
];

export const productIcons = {
  tl: `${iconsUrl}TL.svg`,
  snowflake: `${iconsUrl}snowflake.svg`,
  ms: `${iconsUrl}MS.svg`,
  road: `${iconsUrl}road.svg`,
  truck1: `${iconsUrl}truck-1.svg`,
  truck2: `${iconsUrl}truck-2.svg`,
  truck3: `${iconsUrl}truck-3.svg`,
}