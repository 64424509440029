import React, { useState } from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledProductsSlider from "../../styles/pageparts/ProductsSlider.style";
import { ArrowUp } from "../../utils/icons";
import { productData } from "../../utils/productData";
import SwiperLayout from "../layouts/SwiperLayout";
import Button, { ButtonVariant } from "../units/Button";
import HotSpot from "../units/HotSpot";
import {
  availablePopupTypes,
  usePopupContext,
} from "../../utils/PopupContextProvider";
import Typography from "../units/Typography";

export type ProductsSliderContentType = {
  products: [
    {
      name_part_1: string;
      name_part_2: string;
      description: string;
      hotspots: Array<string>;
      more_info_popup: Array<string>;
      order_link: string;
      download_energy_card_link: string;
    }
  ];
  buttons_labels: {
    more_info: string;
    order: string;
  };
  table_labels: Array<string>;
  download_energy_card: string;
  available: string;
};

const ProductsSlider = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("products_slider") as ProductsSliderContentType;

  const products = content["products"];

  const { setPopupContent, handlePopupState, setProductPopupData } =
    usePopupContext();

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <StyledProductsSlider className="dark-bg" id="products-section" {...p}>
      <SwiperLayout
        className="products-slider"
        updateCurrentIndex={setCurrentIndex}
      >
        {products.map((item, ix) => (
          <div className="product" key={ix}>
            <div className="product-text-section">
              <Typography
                tag="h2"
                variant="heading2"
                bold={true}
                className="product-name"
              >
                Across
                <span className="green-bar"></span>
                {productData[ix].product}
              </Typography>
              <Typography tag="p" variant="largeBody">
                {item["description"]}
              </Typography>
            </div>
            <div className="product-img">
              <div className="wrapper">
                <div className="hotspots">
                  {item["hotspots"].map((hotspot, i) => (
                    <HotSpot
                      top={productData[ix].hotspotPositions[i].y}
                      left={productData[ix].hotspotPositions[i].x}
                      iconSrc={productData[ix].hotspotIcons[i]}
                      style={{ zIndex: 100 - i }}
                      disabled={currentIndex !== ix}
                      key={i}
                    >
                      {hotspot}
                    </HotSpot>
                  ))}
                </div>
                <img
                  src={productData[ix].productPicSrc}
                  alt={`${productData[ix].product} product`}
                  className="product"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="btns">
              <Button
                tag="button"
                variant={ButtonVariant.TRANSPARENT}
                // @ts-ignore
                onClick={() => {
                  setPopupContent(availablePopupTypes.PRODUCT);
                  setProductPopupData(ix);
                  handlePopupState();
                }}
              >
                {content["buttons_labels"]["more_info"]}
              </Button>
              <Button icon={ArrowUp} href={item['order_link']}>
                {content["buttons_labels"]["order"]}
              </Button>
            </div>
          </div>
        ))}
      </SwiperLayout>
    </StyledProductsSlider>
  );
};

export default ProductsSlider;
