import React, { useEffect, useRef, useState } from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledNavHeader from "../../styles/globals/NavHeader.style";
import { useBreakpointContext } from "../../utils/BreakpointContextProvider";
import LanguageSwitcher from "../units/LanguageSwitcher";
import {
  availablePopupTypes,
  usePopupContext,
} from "../../utils/PopupContextProvider";
import { logo, logoName } from "../../utils/globalContent";

type NavHeaderContentType = {
  nav_items: Array<string>;
};

const NavHeader = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const smallScreenSize = useBreakpointContext().isDown("laptop");

  const content = GetText("nav_header") as NavHeaderContentType;

  // POPUP
  const { setPopupContent, handlePopupState } = usePopupContext();

  // MENU - MOBILE or DESKTOP
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenu = () => {
    if (!smallScreenSize) return;

    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.body.classList.toggle("no-scroll");
  };

  // FIXED NAV BAR
  const navBarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!navBarRef.current) return;

      if (window.scrollY <= 80) {
        (navBarRef.current as Element).classList.remove("scrolled");
      } else {
        (navBarRef.current as Element).classList.add("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navigation = () => {
    return (
      <nav
        className={`${smallScreenSize ? "mobile-nav" : "desktop-nav"} ${
          isMobileMenuOpen ? "active" : ""
        }`}
      >
        <ul className="nav-items">
          <li onClick={handleMobileMenu}>
            <a href={`${window.origin}/#about-us-section`} className="nav-link">
              {content["nav_items"][0]}
            </a>
          </li>
          <li onClick={handleMobileMenu}>
            <a href={`${window.origin}/#products-section`} className="nav-link">
              {content["nav_items"][1]}
            </a>
          </li>
          <li onClick={handleMobileMenu}>
            <button
              className="nav-link"
              onClick={() => {
                setPopupContent(availablePopupTypes.CONTACT);
                handlePopupState();
              }}
            >
              {content["nav_items"][2]}
            </button>
          </li>
          {smallScreenSize && (
            <LanguageSwitcher className="mobile-language-switcher"></LanguageSwitcher>
          )}
        </ul>
      </nav>
    );
  };

  return (
    <StyledNavHeader ref={navBarRef} {...p}>
      {!smallScreenSize && <LanguageSwitcher></LanguageSwitcher>}
      <div className="line top"></div>
      <div className="menu">
        <div className="logo">
          <div className="min-logo-wrapper">
            <img src={logo} alt="Green Albourgh logo" className="min-logo" />
            <img src={logo} alt="Green Albourgh logo" className="min-logo" />
          </div>
          <img src={logoName} alt="Albourgh logo name" className="logo-name" />
        </div>
        {navigation()}
        {smallScreenSize && (
          <button
            aria-label="Navigation menu button"
            className={`mobile-btn ${isMobileMenuOpen ? "active" : ""}`}
            onClick={handleMobileMenu}
          ></button>
        )}
      </div>
      <div className="line bottom"></div>
    </StyledNavHeader>
  );
};

export default NavHeader;
