import React from "react";
import { Global, css, ThemeProvider } from "@emotion/react";

const remSize = 16;
export const pxToRem = (px: number) => `${px / remSize}rem`;

const breakpoints = [
  {
    name: "desktopBigger",
    size: 1920,
    mediaQuery: "@media (min-width: 1920px)",
    maxQuery: "@media (max-width: 1920px)",
  },
  {
    name: "desktopBig",
    size: 1600,
    mediaQuery: "@media (min-width: 1600px)",
    maxQuery: "@media (max-width: 1600px)",
  },
  {
    name: "desktop",
    size: 1281,
    mediaQuery: "@media (min-width: 1281px)",
    maxQuery: "@media (max-width: 1281px)",
  },
  {
    name: "desktopSmall",
    size: 1025,
    mediaQuery: "@media (min-width: 1025px)",
    maxQuery: "@media (max-width: 1025px)",
  },
  {
    name: "laptop",
    size: 769,
    mediaQuery: "@media (min-width: 769px)",
    maxQuery: "@media (max-width: 769px)",
  },
  {
    name: "tablet",
    size: 501,
    mediaQuery: "@media (min-width: 501px)",
    maxQuery: "@media (max-width: 501px)",
  },
];

const media = {
  ...Object.fromEntries(breakpoints.map((b) => [b.name, b.mediaQuery])),
};

const mediaDown = {
  ...Object.fromEntries(breakpoints.map((b) => [b.name, b.maxQuery])),
};

const colors = {
  rubberBlack: "#121212",
  wetAsphalt: "#1C2E34",
  wetAsphalt80: "#49585D",
  landscapeGreen: "#00B852",
  darkGreen: "#009B45",
  sandDust: "#F4F0E6",
  white: "#FFFFFF",
  error: "#B80000",
  lightGrey: "#C6CED6"
};

const backgrounds = {
  white: css`
    background-color: ${colors.white};
    color: ${colors.wetAsphalt};
  `,
  sandDust: css`
    background-color: ${colors.sandDust};
    color: ${colors.wetAsphalt};
  `,
  landscapeGreen: css`
    background-color: ${colors.landscapeGreen};
    color: ${colors.white};
  `,
  wetAsphalt: css`
    background-color: ${colors.wetAsphalt};
    color: ${colors.white};
  `,
  semiTransparent: css`
    background-color: rgba(20, 23, 26, 0.6);
    color: ${colors.white};
  `,
};

const fontStyles = (family: any, set: { [x: string]: [number, string] }) => {
  return Object.fromEntries(
    Object.entries(set).map(([name, props]) => [
      name,
      fontStyle(family, ...props),
    ])
  );
};

const fontStyle = (family: any, weight: any, style: any) => ({
  fontFamily: family,
  fontWeight: weight,
  fontStyle: style,
});

const weights = {
  light: 400,
  regular: 500,
  semibold: 600,
  bold: 700,
};

const fonts = {
  roboto: {
    ...fontStyles("Roboto, sans-serif", {
      regular: [weights.regular, "normal"],
    }),
    ...fontStyles("Roboto Condensed, sans-serif", {
      condensedlight: [weights.light, "normal"],
      condensedbold: [weights.bold, "normal"],
    }),
  },
  montserrat: {
    ...fontStyles("Montserrat, sans-serif", {
      semibold: [weights.semibold, "normal"],
    }),
  },
};

const padding = {
  small: "15px",
  regular: "162px",
};

const verticalSpacingValues = {
  desktop: {
    small: "100px",
    regular: "120px",
    large: "200px",
    extraLarge: "240px",
  },
  mobile: {
    small: "60px",
    regular: "80px",
    large: "120px",
    extraLarge: "160px",
  },
};

export const Theme = {
  breakpoints,
  media,
  mediaDown,
  colors: {
    ...colors,
  },
  backgrounds,
  fonts,
  typography: {
    heading1: css`
      ${fonts.roboto.condensedlight};
      font-size: ${pxToRem(56)};
      line-height: 90%;
      text-transform: uppercase;
      margin-bottom: 56px;
      ${media.desktopSmall} {
        font-size: ${pxToRem(96)};
      }
    `,
    heading2: css`
      ${fonts.roboto.condensedlight};
      font-size: ${pxToRem(48)};
      line-height: 90%;
      text-transform: uppercase;
      margin-bottom: 24px;
      ${media.desktopSmall} {
        font-size: ${pxToRem(64)};
      }
    `,
    heading3: css`
      ${fonts.roboto.condensedlight};
      font-size: ${pxToRem(40)};
      line-height: 90%;
      text-transform: uppercase;
      margin-bottom: 24px;
      ${media.desktopSmall} {
        font-size: ${pxToRem(48)};
      }
    `,
    heading4: css`
      ${fonts.roboto.condensedlight};
      font-size: ${pxToRem(28)};
      line-height: 90%;
      text-transform: uppercase;
      ${media.desktopSmall} {
        font-size: ${pxToRem(32)};
      }
    `,
    heading5: css`
      ${fonts.roboto.condensedbold};
      font-size: ${pxToRem(24)};
      line-height: 90%;
      text-transform: uppercase;
      margin-bottom: 28px;
    `,
    subtitle: css`
      ${fonts.montserrat.semibold};
      font-size: ${pxToRem(14)};
      line-height: 90%;
      text-transform: uppercase;
    `,
    largeBody: css`
      ${fonts.roboto.regular};
      font-size: ${pxToRem(18)};
      line-height: 150%;
    `,
    mediumBody: css`
      ${fonts.roboto.regular};
      font-size: ${remSize}px;
      line-height: 150%;
    `,
    smallBody: css`
      ${fonts.roboto.regular};
      font-size: ${pxToRem(14)};
      line-height: 150%;
    `,
    extraSmallBody: css`
      ${fonts.roboto.regular};
      font-size: ${pxToRem(14)};
      line-height: 125%;
      ${media.desktopSmall} {
        font-size: ${pxToRem(12)};
      }
    `,
    bold: css`
      font-weight: ${weights.bold};
    `,
  },
  padding,
  paddingStyle: css`
    padding-left: ${padding.small};
    padding-right: ${padding.small};
    ${media.desktopSmall} {
      padding-left: ${padding.regular};
      padding-right: ${padding.regular};
    }
  `,
  verticalSpacingValues,
};

const MyThemeProvider = (p: any) => {
  return (
    <>
      <Global
        styles={css`
          html {
            ${Theme.typography.mediumBody};
            background-color: ${Theme.colors.rubberBlack};
          }
          h1 {
            ${Theme.typography.heading1};
          }
          h2 {
            ${Theme.typography.heading2};
          }
          h3 {
            ${Theme.typography.heading3};
          }
          h4 {
            ${Theme.typography.heading4};
          }
          h5 {
            ${Theme.typography.heading5};
          }
          h6 {
            ${Theme.typography.subtitle};
          }
          .tag {
            display: flex;
            align-items: center;
            ${Theme.typography.subtitle};
            color: ${Theme.colors.landscapeGreen};
            margin-bottom: 24px;

            &::before {
              content: "";
              display: block;
              width: 24px;
              height: 2px;
              margin-right: 10px;
              background-color: ${Theme.colors.landscapeGreen};
            }
          }
          .bold {
            ${Theme.typography.bold}
          }
          section.container {
            ${Theme.paddingStyle}
          }
          .dark-bg {
            ${Theme.backgrounds.wetAsphalt}
          }
          .light-bg {
            ${Theme.backgrounds.sandDust}
          }
          .white-bg {
            ${Theme.backgrounds.white}
          }
          a {
            color: white;
            text-decoration: none;
          }
          ::selection {
            background-color: ${Theme.colors.landscapeGreen};
            color: ${Theme.colors.white};
          }
        `}
      />
      <ThemeProvider theme={Theme} {...p} />
    </>
  );
};
export default MyThemeProvider;
