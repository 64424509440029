import styled from "@emotion/styled";

export default styled.header`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 900;
  transition: transform 0.3s ease-in-out;

  &.scrolled {
    ${({ theme }) => theme.media.desktopSmall} {
      transform: translateY(-42px);
    }

    .menu {
      background-color: ${({ theme }) => theme.colors.wetAsphalt};
    }

    .line.top {
      background-color: transparent;
    }

    .line.bottom {
      transform: scaleX(1.2);
    }
  }

  ${({ theme }) => theme.media.desktopSmall} {
    .language-switcher {
      margin: 8px 32px 8px 0;
    }
  }

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .line {
    display: block;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.3);
    width: calc(100% - 64px);
    height: 1px;
    transition: transform 0.5s ease-in-out, color 0.5s ease-in-out;

    ${({ theme }) => theme.mediaDown.desktopSmall} {
      width: calc(100% - 30px);

      &.top {
        display: none;
      }
    }
  }

  .menu {
    height: 90px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.4s ease-in-out;

    ${({ theme }) => theme.mediaDown.desktopSmall} {
      padding: 0 15px;
    }

    .logo {
      display: flex;
      z-index: 11;

      .logo-name {
        transform: translateX(-20px);
      }

      .min-logo-wrapper {
        display: flex;
        flex-direction: column;
        width: 22px;
        height: 24px;
        overflow: hidden;

        .min-logo {
          transition: transform 0.3s ease-in-out;
        }
      }

      &:hover {
        .min-logo {
          transform: translateY(-100%);
        }
      }
    }

    ${({ theme }) => theme.mediaDown.desktopSmall} {
      border-top: none;
      margin-top: 0;
      height: 72px;

      button.mobile-btn {
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 11;
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: ${({ theme }) => theme.colors.white};
          transition: transform 0.3s ease-in-out;
        }

        &::before {
          transform: translateY(-5px);
        }

        &::after {
          transform: translateY(5px);
        }

        &.active::before {
          transform: translateY(0) rotate(45deg);
        }

        &.active::after {
          transform: translateY(0) rotate(-45deg);
        }
      }
    }

    nav {
      ul.nav-items {
        padding: 0;
        margin: 0;
      }

      li {
        position: relative;
        list-style: none;
      }

      a.nav-link,
      button.nav-link {
        ${({ theme }) => theme.typography.subtitle};
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
      }
    }

    nav.mobile-nav {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100vw;
      height: calc(100vh - 136px);
      ${({ theme }) => theme.backgrounds.wetAsphalt};
      padding-top: 136px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;

      ul.nav-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        row-gap: 64px;
        height: 100%;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.wetAsphalt80};
      }

      &::before {
        top: 72px;
      }

      &::after {
        bottom: 72px;
      }

      a.nav-link,
      button.nav-link {
        font-size: 1.5rem;
      }

      div.language-switcher {
        margin-top: auto;
        margin-bottom: 24px;
      }

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

    nav.desktop-nav {
      height: 100%;

      ul.nav-items {
        display: flex;
        column-gap: 40px;
        height: 100%;
      }

      li {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: ${({ theme }) => theme.colors.landscapeGreen};
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        a.nav-link,
        button.nav-link {
          display: flex;
          align-items: center;
          height: 100%;
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
`;
