import React, { useMemo } from "react";
import MyThemeProvider from "./theme/Theme";
import { BreakpointContextProvider } from "./utils/BreakpointContextProvider";
import { PopupContextProvider } from "./utils/PopupContextProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AlbourghPage from "./AlbourghPage";
import NotFound from "./404";
import Page from "./Page";
import {
  detectPageLanguage,
  LanguageContextProvider,
} from "./languages/LanguageProvider";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Page>
          <AlbourghPage></AlbourghPage>
        </Page>
      ),
      errorElement: (
        <Page>
          <NotFound></NotFound>
        </Page>
      ),
    },
  ]);

  const langObj = useMemo(() => {
    return detectPageLanguage();
  }, []);

  return (
    <MyThemeProvider>
      <BreakpointContextProvider>
        <LanguageContextProvider {...langObj}>
          <PopupContextProvider>
            <RouterProvider router={router} />
          </PopupContextProvider>
        </LanguageContextProvider>
      </BreakpointContextProvider>
    </MyThemeProvider>
  );
}

export default App;
