import styled from "@emotion/styled";

export default styled.section`
  padding-top: 200px;
  padding-bottom: 100px;

  h3.title,
  .tag {
    ${({ theme }) => theme.paddingStyle}
  }

  h3.title {
    margin-bottom: 75px;
    max-width: 54vw;
  }

  .innovative-techniques-swiper {
    .technique {
      ${({ theme }) => theme.paddingStyle}
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 32px;

      .img-wrapper {
        width: 30vw;
        height: 30vw;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text-section {
        width: 100%;
        max-width: 38vw;
        flex-shrink: 2;
      }
    }
  }

  ${({ theme }) => theme.mediaDown.desktopSmall} {
    padding-top: 120px;
    padding-bottom: 60px;

    h3.title {
      margin-bottom: 56px;
      max-width: unset;
    }

    .innovative-techniques-swiper {
      padding-bottom: 100px;

      .technique {
        .img-wrapper {
          width: 40vw;
          height: 40vw;
        }

        .text-section {
          max-width: 50vw;
        }
      }
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    .innovative-techniques-swiper {
      .technique {
        flex-direction: column;

        .img-wrapper {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          overflow: hidden;
          margin-bottom: 48px;

          img {
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .text-section {
          max-width: 100%;
        }
      }
    }
  }
`;
