import React from 'react';
import StyledIcon from '../../styles/units/Icon.style';
import { Theme } from '../../theme/Theme';

const Icon = ({
  d,
  viewBox,
  width = 20,
  height = 20,
  fill,
  ...p
}: {
    d: string;
    viewBox?: string;
    width?: number;
    height?: number;
    fill?: string
    className?: string;
}) => {
  if (!viewBox) viewBox = `0 0 ${width} ${height}`;

  return (
    <StyledIcon
      width={width}
      height={height}
      viewBox={viewBox}
      // @ts-ignore
      fill={fill ? Theme.colors[fill] : "currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      {!!d && <path d={d} />}
    </StyledIcon>
  );
};

export default Icon;