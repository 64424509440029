import styled from "@emotion/styled";

export default styled.section`
  color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img.background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.6;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .text-section {
    max-width: 45vw;
    text-align: center;

    ${({ theme }) => theme.media.desktopBig} {
        max-width: 35vw;
    }

    ${({ theme }) => theme.mediaDown.laptop} {
        max-width: none;
    }
  }
`;
