import { Facebook, Instagram, LinkedIn } from "./icons";

export const socialLinks = [
  {
    socialMedia: "Instagram",
    icon: Instagram,
    link: "https://www.instagram.com/albourgh",
  },
  {
    socialMedia: "Facebook",
    icon: Facebook,
    link: "https://www.facebook.com/Albourgh",
  },
  {
    socialMedia: "LinkedIn",
    icon: LinkedIn,
    link: "https://www.linkedin.com/showcase/albourgh",
  },
];

export const emailAddress = "info@albourgh.com";

// Images, icons and fonts URLs

export const baseUrl = "https://dmz4fv4xha1bt.cloudfront.net/";
export const imagesUrl = `${baseUrl}images/`;
export const iconsUrl = `${baseUrl}icons/`;
export const videosUrl = `${baseUrl}videos/`;

// Logos

export const logo = `${iconsUrl}logo.svg`;
export const logoName = `${iconsUrl}logo-name.svg`;
export const albourghLogo = `${iconsUrl}logo-w-name.svg`;

// Pageparts

export const pagepartImgs = {
  header_w_video: {
    preview: `${imagesUrl}header-preview.webp`,
    shortLoop: `${videosUrl}header-short-loop.mp4`,
    fullVideo: `${videosUrl}home-video.mp4`,
  },
  usps_grid_slider: [
    `${imagesUrl}shutterstock_1928648981.jpg`,
    `${imagesUrl}shutterstock_2165523565+copy+2+-+CMYK.jpg`,
    `${imagesUrl}shutterstock_2016196853+copy+2+-+CMYK.jpg`,
    `${imagesUrl}shutterstock_1959052045+copy+2+-+CMYK.jpg`,
  ],
  brand_experience: `${imagesUrl}shutterstock_2016196853+copy+2+-+CMYK.jpg`,
  innovative_techniques_tabs: [
    `${imagesUrl}shutterstock_1959052045+copy+2+-+CMYK.jpg`,
    `${imagesUrl}shutterstock_2165523565+copy+2+-+CMYK.jpg`,
    `${imagesUrl}shutterstock_1928648981.jpg`,
    `${imagesUrl}shutterstock_2016196853+copy+2+-+CMYK.jpg`,
  ],
  moodmaker_tile: `${imagesUrl}Concept+image+-+front+tyre+-+100cm+300dpi.jpg`,
  not_found: `${imagesUrl}shutterstock_2016196853+copy+2+-+CMYK.jpg`,
};
