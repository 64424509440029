import React, { useContext, useMemo } from "react";
import { createContext } from "react";
import en from "./eng.json";
import fr from "./fr.json";
import nl from "./nl.json";
import de from "./de.json";

type Pageparts =
  | "head"
  | "nav_header"
  | "header_w_video"
  | "about_us"
  | "usps_grid_slider"
  | "products_slider"
  | "brand_experience"
  | "innovative_techniques_tabs"
  | "moodmaker_tile"
  | "contact_us"
  | "footer"
  | "contact_popup"
  | "not_found";

type PageTextContentType = { [key in Pageparts]: Object };

const dictionaryList: { [x: string]: PageTextContentType } = { en, fr, nl, de };

export const availableLanguages: { [x: string]: string } = {
  en: "English",
  fr: "Français",
  nl: "Nederlands",
  de: "Deutsch",
};

export const detectPageLanguage = () => {
  const hostname = window.location.hostname;
  let prod = null;
  let acc = null;
  let local = "en";

  const checkProd =
    hostname.match(/^www\.albourgh\.(nl|de|fr|com)$/) ||
    hostname.match(/^albourgh\.(nl|de|fr|com)$/);
  if (checkProd) {
    prod = checkProd?.[1] === "com" ? "en" : checkProd?.[1];
  } else if (hostname.match(/^.*albourgh\.wiredev\.nl$/)) {
    acc = hostname.match(/^(nl|de|fr)\.albourgh\.wiredev\.nl$/)?.[1] || "en";
  } else {
    local = window.location.pathname.match(/^\/(nl|de|fr)$/)?.[1] || "en";
  }

  if (prod)
    return {
      lang: prod,
      url: (lang: string) => {
        if (lang === "en") {
          return `https://www.albourgh.com`;
        }
        return `https://www.albourgh.${lang}`;
      },
    };
  if (acc)
    return {
      lang: acc,
      url: (lang: string) => {
        if (lang === "en") {
          return `https://albourgh.wiredev.nl`;
        }
        return `https://${lang}.albourgh.wiredev.nl`;
      },
    };
  return {
    lang: local,
    url: (lang: string) => {
      return `http://localhost:3000/${lang}`;
    },
  };
};

const LanguageContext = createContext({
  currentLanguage: "en",
  dictionary: dictionaryList["en"],
  setCurrentLanguage: (a: string) => {},
});

export const LanguageContextProvider = ({
  lang: currentLanguage,
  url,
  children,
}: {
  lang: string;
  url: (a: string) => string;
  children?: React.ReactNode;
}) => {
  const value = useMemo(
    () => ({
      currentLanguage,
      dictionary: dictionaryList[currentLanguage],
      setCurrentLanguage: (newLang: string) => {
        window.location.href = url(newLang);
      },
    }),
    [currentLanguage, url]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  return useContext(LanguageContext);
};

export const GetText = (pagepart: Pageparts) => {
  const { dictionary } = useLanguageContext();
  return dictionary[pagepart];
};
