import Typography from "./components/units/Typography";
import StyledNotFound from "./styles/404.style";
import { GetText } from "./languages/LanguageProvider";
import Button from "./components/units/Button";
import { ChevronRight } from "./utils/icons";
import { pagepartImgs } from "./utils/globalContent";

type NotFoundContentType = {
  title: string;
  description: string;
  button_label: string;
};

const NotFound = () => {
  const content = GetText("not_found") as NotFoundContentType;

  return (
    <StyledNotFound className="container">
      <img
        src={pagepartImgs.not_found}
        alt="Landscape with truck on road"
        className="background"
      />
      <div className="text-section">
        <h3 dangerouslySetInnerHTML={{ __html: content["title"] }}></h3>
        <Typography tag="p" variant="mediumBody" className="mb-56">
            {content["description"]}
        </Typography>
        <Button icon={ChevronRight} href={window.origin}>
            {content["button_label"]}
        </Button>
      </div>
    </StyledNotFound>
  );
};

export default NotFound;
