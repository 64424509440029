import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledAboutUs from "../../styles/pageparts/AboutUs.style";

type AboutUsContentType = {
  title: string;
  tag: string;
  description: string;
};

const AboutUs = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("about_us") as AboutUsContentType;

  return (
    <>
      <div id="about-us-section" style={{ position: "relative", top: "200px" }}></div>
      <StyledAboutUs className="container" {...p}>
        <div className="text-content">
          <div className="tag">{content["tag"]}</div>
          <h3 dangerouslySetInnerHTML={{ __html: content["title"] }}></h3>
          <p>{content["description"]}</p>
        </div>
      </StyledAboutUs>
    </>
  );
};

export default AboutUs;
