import styled from "@emotion/styled";

export default styled.section`
  padding-top: 250px;
  padding-bottom: 60px;
  color: ${({ theme }) => theme.colors.white};

  video.background {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.8;
  }

  video.full-video {
    display: none;

    &.active {
      display: block;
    }
  }

  h1 {
    max-width: 45vw;
  }

  a.full.green {
    margin-right: 16px;
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    padding-top: 150px;
    padding-bottom: 240px;

    h1 {
      max-width: none;
      white-space: normal;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    a.full,
    button.full {
      width: 100%;
      text-align: center;

      &.green {
        margin-bottom: 15px;
      }

      .btn {
        width: 100%;
      }
    }
  }
`;
