import styled from "@emotion/styled";

export default styled.section`
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;

  div.usp-item {
    position: relative;
    z-index: 1;
    flex: 1;
    height: 70vh;
    padding: 32px;
    border: 1px solid ${({ theme }) => theme.colors.wetAsphalt80};
    margin-left: 15px;
    margin-right: 15px;

    p,
    .heading5 {
      position: relative;
      z-index: 10;
    }

    .img-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.rubberBlack};

      img.background {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.8;
      }
    }
  }

  ${({ theme }) => theme.media.desktopSmall} {
    padding-top: 100px;
    padding-bottom: 100px;

    div.usp-item {
      padding: 48px;
      margin: 0;

      .img-wrapper {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      p {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      }

      &:hover {
        .img-wrapper,
        p {
          opacity: 1;
        }

        p {
          transform: translateY(0);
        }
      }
    }
  }
`;
