import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledInnovativeTechniquesTabs from "../../styles/pageparts/InnovativeTechniquesTabs.style";
import { pagepartImgs } from "../../utils/globalContent";
import SwiperLayout, {
  SwiperScreenSize,
  SwiperType,
} from "../layouts/SwiperLayout";
import Typography from "../units/Typography";

type InnovativeTechContentType = {
  title: string;
  tag: string;
  tabs: [
    {
      title: string;
      description: string;
    }
  ];
};

const InnovativeTechniquesTabs = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText(
    "innovative_techniques_tabs"
  ) as InnovativeTechContentType;

  const paginationItems = content["tabs"].map((item) => item["title"]);

  return (
    <StyledInnovativeTechniquesTabs className="light-bg" {...p}>
      <div className="tag">{content["tag"]}</div>
      <h3
        dangerouslySetInnerHTML={{ __html: content["title"] }}
        className="title"
      ></h3>
      <SwiperLayout
        swiperScreenSize={SwiperScreenSize.ALWAYS}
        swiperType={SwiperType.PAGINATION}
        className="innovative-techniques-swiper"
        swiperBtnStyle="dark"
        paginationItems={paginationItems}
      >
        {content["tabs"].map((item, ix) => (
          <div className="technique" key={ix}>
            <div className="img-wrapper">
              <img
                src={pagepartImgs.innovative_techniques_tabs[ix]}
                alt={item["title"]}
                loading="lazy"
              />
            </div>
            <div className="text-section">
              <Typography
                tag="h4"
                variant="heading4"
                bold={true}
                className="mb-24"
              >
                {item["title"]}
              </Typography>
              <Typography tag="p" variant="largeBody">
                {item["description"]}
              </Typography>
            </div>
          </div>
        ))}
      </SwiperLayout>
    </StyledInnovativeTechniquesTabs>
  );
};

export default InnovativeTechniquesTabs;
