import { GetText } from "../../languages/LanguageProvider";
import StyledProductPopup from "../../styles/units/ProductPopup.style";
import { ArrowUp, TruncatedChevron } from "../../utils/icons";
import { productData, productIcons } from "../../utils/productData";
import { ProductsSliderContentType } from "../pageparts/ProductsSlider";
import Button from "./Button";
import Icon from "./Icon";
import Typography from "./Typography";

const ProductPopup = ({ index, ...p }: { index: number }) => {
  const content = GetText("products_slider") as ProductsSliderContentType;
  const productInfo = content["products"][index]["more_info_popup"];
  const downloadLink = content["products"][index]["download_energy_card_link"];
  const orderBtnLabel = content["buttons_labels"]["order"];
  const tableLabels = content["table_labels"];
  const downloadLabel = content["download_energy_card"];
  const availableLabel = content["available"];

  return (
    <StyledProductPopup {...p}>
      <div className="energy-card">
        <div className="energy-img">
          <img
            src={productData[index].energyImgSrc}
            alt={`${productData[index].product} energy labels card`}
          />
        </div>
        <a
          className="download-card"
          href={downloadLink}
          target="_blank"
          download
          rel="noreferrer"
        >
          {downloadLabel}
        </a>
      </div>
      <div className="info-section">
        <Typography
          tag="h2"
          variant="heading2"
          bold={true}
          className="product-name"
        >
          Across
          <span className="green-bar"></span>
          {productData[index].product}
        </Typography>
        <ul className="product-features mb-32">
          {productInfo.map((item, ix) => (
            <li key={ix}>
              <Icon {...TruncatedChevron} fill="landscapeGreen" />
              {item}
            </li>
          ))}
        </ul>
        <Button icon={ArrowUp} href={content["products"][index]["order_link"]}>
          {orderBtnLabel}
        </Button>
        <div className="table-data">
          <table>
            <thead>
              <tr>
                {tableLabels.map((label, i) => (
                  <th key={i}>
                    <Typography variant="subtitle" color="landscapeGreen">
                      {label}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {productData[index].tableData.map((row, j) => (
                <tr key={j}>
                  <td className="bold">{row.size}</td>
                  <td>{row.index}</td>
                  <td
                    className={row.availability === "now" ? "green" : ""}
                  >
                    {row.availability === "now" ? availableLabel : row.availability}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="divider"></div>
          <div className="bottom-section">
            <Button
              icon={ArrowUp}
              href={content["products"][index]["order_link"]}
            >
              {orderBtnLabel}
            </Button>
            <div className="icons">
              <img src={productIcons.tl} alt="TL icon" loading="lazy" />
              <img
                src={productIcons.snowflake}
                alt="Snowflake icon"
                loading="lazy"
              />
              <img src={productIcons.ms} alt="MS icon" loading="lazy" />
              <img src={productIcons.road} alt="Road icon" loading="lazy" />
              <img
                // @ts-ignore
                src={productIcons[`truck${index + 1}`]}
                alt="Truck icon"
                className="truck-icon"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledProductPopup>
  );
};

export default ProductPopup;
