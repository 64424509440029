import styled from "@emotion/styled";

export default styled.section`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  &::before {
    content: "";
    position: absolute;
    top: 52%;
    left: 50%;
    width: 300%;
    z-index: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.landscapeGreen};
    transform-origin: center center;
    transform: translateX(-50%) rotate(30deg);
  }

  div.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

  .product-text-section {
    max-width: 46vw;
    opacity: 0;
    transform: translateY(25%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    margin-bottom: 16px;

    .product-name {
      display: flex;
      align-items: center;
      justify-content: center;

      .green-bar {
        height: 8px;
        width: 70px;
        margin: 0 15px;
        background-color: ${({ theme }) => theme.colors.landscapeGreen};
      }
    }
  }

  .product-img {
    position: relative;
    width: 35vw;
    margin-bottom: 42px;

    .wrapper {
      width: 100%;
      height: 0;
      padding-bottom: 110%;
    }

    img.product {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .btns {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    transition-delay: 0s;
  }

  .swiper-slide-active {
    .product-text-section {
      opacity: 1;
      transform: translateY(0);
    }

    .btns {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      transition-delay: 0.3s;
    }
  }

  ${({ theme }) => theme.media.desktopBig} {
    .product-img {
      width: 25vw;
    }
  }

  ${({ theme }) => theme.mediaDown.laptop} {
    padding-top: 60px;
    padding-bottom: 60px;

    div.product {
      height: fit-content;
      ${({ theme }) => theme.paddingStyle}
    }

    .product-text-section {
      max-width: 100%;

      .product-name .green-bar {
        height: 6px;
      }
    }

    .product-img {
      width: 100%;
    }

    .btns {
      flex-direction: column;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
`;
