import React from "react";
import StyledButton from "../../styles/units/Button.style";
import { IconType } from "../../utils/icons";
import Icon from "./Icon";

export enum ButtonVariant {
  FULL_GREEN = "full green",
  FULL_SEMI_TRANSPARENT = "full semi-transparent",
  TRANSPARENT = "transparent",
}

const Button = ({
  variant = ButtonVariant.FULL_GREEN,
  icon,
  tag = "a",
  onCustomClick,
  children,
  ...p
}: {
  variant?: ButtonVariant;
  icon?: IconType;
  tag?: string;
  onCustomClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  type?: string;
  href?: string;
}) => {
  return (
    // @ts-ignore
    <StyledButton className={variant} as={tag} onClick={onCustomClick} {...p}>
      <span className="btn">{children}</span>
      {variant !== ButtonVariant.TRANSPARENT && icon && (
        <span className="btn-icon">
          <Icon {...icon}></Icon>
        </span>
      )}
    </StyledButton>
  );
};

export default Button;
