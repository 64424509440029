import React from "react";
import { GetText } from "../../languages/LanguageProvider";
import StyledBrandExperienceWImg from "../../styles/pageparts/BrandExperienceWImg.style";
import { imagesUrl, pagepartImgs } from "../../utils/globalContent";

type BrandExpContentType = {
  title: string;
  description: string;
};

const BrandExperienceWImg = ({
  children,
  ...p
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const content = GetText("brand_experience") as BrandExpContentType;

  return (
    <StyledBrandExperienceWImg className="container dark-bg" {...p}>
      <div className="txt-and-img">
        <img src={pagepartImgs.brand_experience} alt="Default" className="brand-img" loading="lazy" />
        <div className="text-section">
          <h3 dangerouslySetInnerHTML={{ __html: content["title"] }}></h3>
          <p>{content["description"]}</p>
        </div>
        <div className="decorative-items">
          <img src={`${imagesUrl}Across-D.png`} alt="Tyre" className="tire" loading="lazy" />
          <picture>
            <source
              media="(min-width: 769px)"
              srcSet={`${imagesUrl}tire-pattern.svg`}
            />
            <img
              src={`${imagesUrl}tire-pattern-mobile.svg`}
              alt="Tire pattern outlined"
              className="pattern"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </StyledBrandExperienceWImg>
  );
};

export default BrandExperienceWImg;
