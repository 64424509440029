import styled from "@emotion/styled";

export default styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;

  .styled-input {
    input & {
      height: 52px;
    }
  }

  .styled-input {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    position: relative;
    ${({ theme }) => theme.typography.mediumBody}
    ${({ theme }) => theme.backgrounds.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.wetAsphalt};
    margin-bottom: 28px;
    transition: border-bottom-color 0.3s ease-in-out;

    &.half-width {
      flex-basis: 40%;

      ${({ theme }) => theme.mediaDown.laptop} {
        flex-basis: 100%;
      }
    }

    &:focus-within label.styled-label {
      transform: translateY(2px) scale(0.75);
      color: ${({ theme }) => theme.colors.wetAsphalt80};
    }

    &.has-value {
      border-bottom: 1px solid ${({ theme }) => theme.colors.wetAsphalt80};

      & label.styled-label {
        transform: translateY(2px) scale(0.75);
      }
    }

    .error-msg {
      position: absolute;
      top: 100%;
      left: 16px;
      transform: translateY(4px);
      ${({ theme }) => theme.typography.extraSmallBody}
      color: ${({ theme }) => theme.colors.error};
    }

    &.error {
      border-bottom-color: ${({ theme }) => theme.colors.error};

      & label.styled-label {
        color: ${({ theme }) => theme.colors.error};
      }
    }

    &.success {
      border-bottom-color: ${({ theme }) => theme.colors.landscapeGreen};

      & label.styled-label {
        color: ${({ theme }) => theme.colors.landscapeGreen};
      }
    }
  }

  label.styled-label {
    position: absolute;
    left: 16px;
    transform-origin: left center;
    transform: translateY(14px);
    pointer-events: none;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  input:not([type="checkbox"]),
  textarea {
    outline: none;
    border: none;
    width: 100%;
    padding: 22px 16px 6px 16px;
    ${({ theme }) => theme.typography.mediumBody}
  }

  label.checkbox-wrapper {
    position: relative;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .error-msg {
      position: absolute;
      top: 100%;
      left: 36px;
      transform: translateY(4px);
      ${({ theme }) => theme.typography.extraSmallBody}
      color: ${({ theme }) => theme.colors.error};
    }
  }

  input[type="checkbox"] {
    appearance: none;
    outline: none;
    border: none;
    margin: 0;
    margin-right: 12px;
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid ${({ theme }) => theme.colors.wetAsphalt};
    background-color: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 18px;
      height: 18px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.colors.landscapeGreen};
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:checked::before {
      opacity: 1;
    }
  }
`;
